$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$lightgray: rgb(240, 240, 240);
$lightMiddlegray: rgb(210, 210, 210);
$darkgray: rgb(150, 150, 150);
$lightgreen: rgb(239, 252, 239);
$lightMiddlegreen: rgb(232, 250, 232);
$lightMiddlegreen2: rgb(226, 240, 226);
$green: #b7e7b7;
$darkMiddlegreen: rgb(78, 150, 78);
$darkgreen: #1c691c;
$lightyellow: rgb(241, 236, 205);
$grayGreen: rgb(168 189 168);
$lightOrange: rgb(245 218 187);
$red: rgb(255, 48, 48);
$transparent: transparent;

$themes: (
  back: (
    darkTheme: $darkgreen,
    lightTheme: $lightgreen
  ),
  color: (
    darkTheme: $lightgreen,
    lightTheme: $darkgreen
  ),
  buttonBack1: (
    darkTheme: $darkgray,
    lightTheme: $lightgray
  ),
  buttonColor1: (
    darkTheme: $lightgray,
    lightTheme: $darkgreen
  ),
  buttonBorder1: (
    darkTheme: $darkgray,
    lightTheme: $darkgray
  ),
  buttonBack2: (
    darkTheme: $darkgray,
    lightTheme: $darkgreen
  ),
  buttonColor2: (
    darkTheme: $lightgray,
    lightTheme: $lightgreen
  ),
  buttonBorder2: (
    darkTheme: $darkgray,
    lightTheme: $darkgray
  ),
  buttonBack3: (
    darkTheme: $transparent,
    lightTheme: $white
  ),
  buttonColor3: (
    darkTheme: $lightgreen,
    lightTheme: $darkgreen
  ),
  buttonBorder3: (
    darkTheme: $transparent,
    lightTheme: $lightMiddlegray
  ),
  buttonBack4: (
    darkTheme: $transparent,
    lightTheme: $transparent
  ),
  buttonColor4: (
    darkTheme: $lightgreen,
    lightTheme: $darkgreen
  ),
  buttonBorder4: (
    darkTheme: $transparent,
    lightTheme: $transparent
  ),
  buttonBack5: (
    darkTheme: $transparent,
    lightTheme: $red
  ),
  buttonColor5: (
    darkTheme: $lightgreen,
    lightTheme: $white
  ),
  buttonBorder5: (
    darkTheme: $transparent,
    lightTheme: $red
  ),
  cardBack1: (
    darkTheme: $darkgray,
    lightTheme: $lightgray
  ),
  cardColor1: (
    darkTheme: $lightgray,
    lightTheme: $darkgray
  ),
  cardBoxShadow1: (
    darkTheme: $lightgray,
    lightTheme: $darkgray
  ),
  panelBack1: (
    darkTheme: $darkgreen,
    lightTheme: $lightgreen
  ),
  panelColor1: (
    darkTheme: $lightgreen,
    lightTheme: $darkgreen
  ),
  panelColor2: (
    darkTheme: $lightgray,
    lightTheme: $darkgray
  ),
  panelColorHover: (
    darkTheme: $darkgray,
    lightTheme: $lightgray
  ),
  tableColor: (
    darkTheme: $darkgray,
    lightTheme: $darkgreen
  ),
  tableBackTh: (
    darkTheme: $darkgray,
    lightTheme: $lightMiddlegray
  ),
  tableBackTd: (
    darkTheme: $darkgray,
    lightTheme: $lightgray
  ),
  helpItemBack: (
    darkTheme: $darkgray,
    lightTheme: $lightyellow
  ),
  helpItemColor: (
    darkTheme: $lightyellow,
    lightTheme: $darkgray
  ),
  section: (
    darkTheme: $darkgray,
    lightTheme: $lightMiddlegreen2
  ),
  sectionDanger: (
    darkTheme: $darkgray,
    lightTheme: $lightOrange
  ),
  placeholder: (
    darkTheme: $darkgray,
    lightTheme: $grayGreen
  ),
  labelBack1: (
    darkTheme: $darkgray,
    lightTheme: $lightMiddlegray
  ),
  infoSuccessBack: (
    darkTheme: $darkgray,
    lightTheme: $lightMiddlegreen2
  ),
  infoWarningBack: (
    darkTheme: $darkgray,
    lightTheme: $lightOrange
  ),
  infoRequired: (
    darkTheme: $red,
    lightTheme: $red
  )
);

@mixin styles($mode) {
  @each $key, $map in $themes {
    @each $prop, $color in $map {
      @if $prop == $mode {
        --#{$key}: #{$color};
      }
    }
  }
}

.dark-mode {
  @include styles("darkTheme");
}

.light-mode {
  @include styles("lightTheme");
}

body {
  background-color: var(--back) !important;
  color: var(--color) !important;
}

hr {
  color: inherit;
  border: 0px solid gray;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
  width: 30%;
}

img {
  object-fit: cover;
}

input[type="radio"] {
  margin-right: 10px;
}

#main {
  display: flex;
  flex-direction: column;
}

.offlineMask {
  text-align: center;
  font-weight: bold;
  background-color: antiquewhite;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#bodyMain {
  transition: all ease-in-out 0.2s;
}

#headerMain {
  position: fixed;
  top: 0px;
  background-color: white;
  box-shadow: 0px 5px 5px 0px lightgray;
  //position: fixed;
  z-index: 20 !important;
  height: 80px;
}

#footerPwa {
  position: fixed;
  bottom: 0px;
  background-color: white;
  height: 80px;
  z-index: 999;
  a {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    color: var(--panelColor1);
    //border-radius: 5px;
    padding: 5px;
  }
}

#headerPwaMain {
  background-color: white;
  box-shadow: 0px 5px 5px 0px lightgray;
  position: fixed;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20 !important;
  transition: all ease-in-out 0.2s;
  height: 30px;
}

#footerMain {
  //position: absolute;
  //bottom: 0;
  width: 100%;
  background-color: white;
  padding: 20px;
  border-top: 5px solid rgb(196, 195, 195);
  z-index: 10;
  flex: 1 1 auto;
  // box-shadow: 1px 1px 10px 1px gray;
  //margin-top: 50px;
  //position: fixed;
  //bottom: 0px;
  //width: 100%;
}

.modal-header {
  position: sticky !important;
  top: 0px !important;
  z-index: 6 !important;
  background-color: white !important;
}

.modal-dialog {
  padding-bottom: 100px !important;
}

.modal-footer {
  justify-content: space-between !important;
}

.btn-placeholder {
  background-color: var(--placeholder) !important;
  border: 1px solid var(--placeholder) !important;
}

.buttonPwa {
  padding: 5px !important;
}

.btn-custom1 {
  background-color: var(--buttonBack1) !important;
  color: var(--buttonColor1) !important;
  border: var(--buttonBorder1) 1px solid !important;
  padding: 8px !important;
  margin: 5px !important;
  line-height: 1em !important;
  text-align: center !important;
  border-radius: 3px !important;
  &:hover {
    //background-color: var(--buttonBack2) !important;
    //color: var(--buttonColor2) !important;
  }
}

.btn-custom2 {
  background-color: var(--buttonBack2) !important;
  color: var(--buttonColor2) !important;
  border: var(--buttonBorder2) solid 1px !important;
  padding: 12px !important;
  margin: 5px !important;
  line-height: 1em !important;
  text-align: center !important;
  border-radius: 3px !important;
  &:hover {
    //background-color: var(--buttonBack3) !important;
    //color: var(--buttonColor3) !important;
  }
}

.btn-custom3 {
  background-color: var(--buttonBack3) !important;
  color: var(--buttonColor3) !important;
  border: var(--buttonBorder3) solid 1px !important;
  padding: 12px !important;
  margin: 5px !important;
  line-height: 1em !important;
  border-radius: 3px !important;
  text-align: center !important;

  &:hover {
    background-color: var(--buttonBack1) !important;
    //color: var(--buttonColor3) !important;
  }
}

.btn-custom4 {
  background-color: var(--buttonBack4) !important;
  color: var(--buttonColor4) !important;
  border: var(--buttonBorder4) solid 1px !important;
  padding: 12px !important;
  margin: 5px !important;
  line-height: 1em !important;
  border-radius: 3px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  &:hover {
    background-color: var(--buttonBack1) !important;
    //color: var(--buttonColor3) !important;
  }
}

.btn-custom5 {
  background-color: var(--buttonBack5) !important;
  color: var(--buttonColor5) !important;
  border: var(--buttonBorder5) solid 1px !important;
  padding: 12px !important;
  margin: 5px !important;
  line-height: 1em !important;
  border-radius: 3px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  &:hover {
    background-color: var(--buttonBack5) !important;
    //color: var(--buttonColor3) !important;
  }
  svg {
    color: var(--buttonColor5) !important;
  }
}

.btn-custom6 {
  //background-color: var(--buttonBack1) !important;
  //color: var(--buttonColor1) !important;
  //border: var(--buttonBorder1) 1px solid !important;
  padding: 8px !important;
  margin: 5px !important;
  line-height: 1em !important;
  text-align: center !important;
  border-radius: 3px !important;
  text-decoration: none;
  &:hover {
    //background-color: var(--buttonBack2) !important;
    //color: var(--buttonColor2) !important;
  }
}

//.buttonMenuPwa:hover {
//animation: shake 0.2s ease-in-out;
//}

.buttonMenuPwa {
  width: 40px;
}

.form-check-input:checked {
  background-color: var(--buttonBack2) !important;
  color: var(--buttonBack2) !important;
}

.card-custom1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--cardBack1) !important;
  /*color:var(--cardColor1) !important;*/
  border: none !important;
  padding: 30px !important;
  margin: 20px 5px !important;
  line-height: 1em !important;
  box-shadow: 5px 5px 20px 0px var(--cardBoxShadow1) !important;
  //width: 75% !important;
  //max-width: 500px;
  margin-left: auto !important;
  margin-right: auto !important;
  min-width: 300px !important;

  input {
    //width: 100%;
  }
  .form-check {
    display: flex;
    align-items: center;
  }
}

.card-custom2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--cardBack1) !important;
  /*color:var(--cardColor1) !important;*/
  border: none !important;
  padding: 20px !important;
  margin: 5px !important;
  line-height: 1em !important;
  box-shadow: 3px 3px 5px 0px var(--cardBoxShadow1) !important;
  margin-left: auto !important;
  margin-right: auto !important;
  min-width: 300px !important;
  border-radius: 3px;

  input {
    width: 100%;
  }
  ul {
    li {
      line-height: 1.5em;
      //list-style-type: none;
    }
  }
}

.card-custom3 {
  background-color: var(--section) !important;
  box-shadow: rgb(0 0 0 / 16%) 5px 5px 10px;
  margin-bottom: 10px;
}

.card-custom4 {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background-color: var(--cardBack1) !important;
  /*color:var(--cardColor1) !important;*/
  border: none !important;
  padding: 20px !important;
  margin: 20px 5px !important;
  line-height: 1em !important;
  box-shadow: 5px 5px 20px 0px var(--cardBoxShadow1) !important;
  //width: 75% !important;
  //max-width: 500px;
  margin-left: auto !important;
  margin-right: auto !important;
  min-width: 300px !important;
}

.helpItem {
  border-radius: 5px;
  //background-color: var(--helpItemBack);
  color: var(--helpItemColor);
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9em;
  a {
    margin-left: 10px;
  }
}

.containerSignIn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  box-shadow: rgb(0 0 0 / 16%) 5px 5px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 3px;
  background-color: rgb(236, 236, 236);
}

.formSignIn {
  height: 100%;
}

.selectedNav {
  position: absolute;
  transition: all 0.5 ease-in-out;
  border-bottom: 5px solid rgb(28, 105, 28);
}

.offcanvas {
  margin-bottom: 80px;
}
.offcanvas-backdrop {
  z-index: 997 !important;
}
.offcanvas-bottom {
  z-index: 9999 !important;
  height: initial !important;
  margin-bottom: 0px;
}
.offcanvas-end,
.offcanvas-start {
  z-index: 998 !important;
}

.offcanvas-body {
  ul {
    padding-left: 6px;
    li {
      color: var(--panelColor1);
      margin-bottom: 10px;
      padding-bottom: 0px;
      font-size: 1.5em;
      list-style-type: none;
      font-weight: 500;
      img {
        height: 24px;
        margin-right: 10px;
      }
      ul {
        padding-left: 20px;
        li {
          font-size: 0.8em;
          margin-bottom: 0px;
        }
        i {
          height: 32px;
          margin-right: 10px;
        }
      }
    }
    a {
      svg,
      i {
        margin-right: 10px;
        max-height: 24px;
        max-width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none !important;
      color: var(--panelColor1);
      border-radius: 5px;
      width: 100%;
      padding: 5px 5px;
      &:hover {
        //border-bottom: 5px solid var(--panelColor2);
        background-color: var(--panelColorHover);
        //color: var(--panelColor3);
      }
    }

    hr {
      height: 2px;
      margin-top: 0px;
      margin-bottom: 10px;
      overflow: hidden;
      border-top: 1px solid var(--bs-dropdown-divider-bg);
      opacity: 0.5;
      background-color: var(--panelColor1);
      width: 100%;
    }
  }
}

table {
  border-collapse: collapse;
  text-align: center;
  border-radius: 50px;
  border: 1px solid var(--buttonBorder1);
  th {
    background-color: var(--tableBackTh);
    padding: 5px;
  }
  td {
    text-align: center;
    padding: 5px;
    background-color: var(--tableBackTd);
    border: 1px solid var(--buttonBorder1);
  }

  &.tableTransparent {
    border-collapse: collapse;
    border: none;
    thead {
      display: none;
    }
    td {
      text-align: center;
      padding: 3px;
      background-color: transparent;
      border: none;
      //border-bottom: 1px solid var(--buttonBorder1);
    }
    tr {
      border-bottom: 1px solid var(--buttonBorder1);
    }
  }

  &.tableSemiTransparent {
    border-collapse: collapse;
    border: 1px solid var(--buttonBorder2);
    thead {
      //display: none;
    }
    td {
      text-align: center;
      padding: 3px;
      background-color: white;
      border: 1px solid var(--buttonBorder2);
      //border-bottom: 1px solid var(--buttonBorder1);
    }
    tr {
      border-bottom: 1px solid var(--buttonBorder1);
    }
  }
}

.form-switch {
  display: flex !important;
  align-items: center;
  margin: 10px;
  input {
    height: 2em;
    width: 4em !important;
    margin-right: 10px;
    &:not(checked) {
      background-color: var(--buttonBack2) !important;
    }
    &:checked {
      background-color: var(--buttonBack2) !important;
    }
  }
}
.powr-social-feed iframe {
  //height: 1500px !important;
  //background-color: #d0f9d0;
  border-radius: 5px;
  padding: 10px;
  //border: 2px solid gray;
  //box-shadow: 5px 5px 20px 0px var(--cardBoxShadow1) !important;
  //padding-bottom: 75px;
}

div.alert {
  /* margin: 20px; */
  width: calc(100% - 40px);
  max-width: 700px;
  position: fixed;
  top: 40px;
  transform: translateX(-50%);
  left: 50%;
  transition: 0.3s ease-in-out all;
  z-index: 100;
  font-size: 0.8em;
}

.facebook {
  color: white !important;
  background-color: #3f6fb7 !important;
  border-radius: 6px !important;
  i {
    margin-right: 4px;
  }
}

.whatsapp {
  color: white !important;
  background-color: #1db05f !important;
  border-radius: 5px !important;
  i {
    margin-right: 0px;
    margin-top: -4px;
  }
}

.qrCodeBadlevier {
  height: 64px;
  width: 64px;
}

.divPhotoEvent > div {
  height: 100%;
}

.creditJohn {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  hr {
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
  }
}

.section {
  position: sticky;
  background-color: var(--section);
  z-index: 4;
  padding: 10px;
  top: 30px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 8px 0px #e3e3e3;
  margin-bottom: 10px;
  //border: 2px solid green;
}

.sectionDanger {
  position: sticky;
  background-color: var(--sectionDanger);
  z-index: 1;
  padding: 10px;
  top: 60px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 8px 0px #e3e3e3;
  margin-bottom: 10px;
  //border: 2px solid green;
}

input[type="checkbox"] {
  cursor: pointer;
}

.form-switch {
  margin: 10px;
  input {
    height: 1em;
    width: 2em !important;
  }
}

.switchDarkMode {
  display: none !important;
}

.list1 {
  display: flex;
  align-items: center;
  //flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 4px 4px 6px -1px lightgray;
  margin-bottom: 10px;
  background-color: $white;
  &:hover {
    background-color: lightblue;
  }
  .col {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin: 3px;
    overflow: hidden;
    flex-wrap: wrap;
    label {
      /* background-color: var(--labelBack1); */
      border-radius: 2px;
      padding: 0px;
      color: gray;
      margin-right: 12px;
      font-style: italic;
    }
  }
}

.list2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 4px 4px 6px -1px lightgray;
  margin-bottom: 10px;
  background-color: $white;
  &:hover {
    background-color: lightblue;
  }
  .col {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin: 3px;
    overflow: hidden;
    flex-wrap: wrap;
    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 2px;
      padding: 0px;
      color: gray;
      margin-right: 12px;
      font-style: italic;
    }
  }
}

.textEllipse {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.infoSuccess {
  font-size: 0.8em;
  background-color: var(--infoSuccessBack);
  border-radius: 5px;
  padding: 3px;
}

.infoWarning {
  background-color: var(--infoWarningBack);
  font-size: 0.8em;
  border-radius: 5px;
  padding: 3px;
}

.form-floating.required {
  color: var(--infoRequired);
  /* background-color: var(--labelBack1); */
  padding: 2px;
  border-radius: 5px;
  margin-bottom: 6px;
  display: block;
  position: relative;
  label:after {
    content: " (obligatoire)";
  }
}

.accordion-item {
  :not(.form-floating) > label {
    background-color: var(--buttonBack1);
    padding: 5px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
    span {
      margin-left: 5px;
      font-weight: 500;
      flex: 1 1 auto;
      text-align: right;
    }
  }
}

.labelKeyValue {
  background-color: var(--buttonBack1);
  padding: 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: "wrap";
  justify-content: space-between;
  margin-bottom: 5px;
}

.accordion-button {
  padding: 10px !important;
}

button.buttonChecked {
  background-color: var(--buttonBack2) !important;
}

.PhoneInput {
  input {
    font-size: 1em;
    padding: 10px;
  }
}

.carousel-container {
  padding: 10px;
  margin: 0px 0px;
}

.carousel-inner {
  height: 100%;
}

.carousel-indicators [data-bs-target] {
  background-color: #5c8766 !important;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%231c691c%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%231c691c%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}
.carousel-item {
  padding-left: 50px;
  padding-right: 50px;
  transition: all 0.5s ease-in-out;
}

.carousel-item.active {
  display: block;
  padding: 0px;
}

.carousel-caption {
  position: relative;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #5c8766 !important;
  text-align: center;
}

.carousel-control-prev-icon {
}

button[role="tab"] {
  color: var(--color) !important;
}

.btn[disabled] {
  opacity: 0.5;
}

.statusMouvement {
  color: darkgreen;
  position: relative;
  &:before {
    content: "";
    height: 12px;
    width: 12px;
    position: absolute;
    top: -17px;
    left: 50%;
    background-color: green;
    border-radius: 50%;
    border: 1px solid darkgreen;
    opacity: 1;
    transform: translateX(-50%);
  }
  &.reached:before {
    content: "\2713";
    height: 12px;
    width: 12px;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    border-radius: 50%;
    border: none;
    opacity: 1;
    font-weight: bold;
  }
  &.reached {
    font-weight: bold;
    opacity: 1;
  }
  &:not(.reached) {
    opacity: 0.7;
  }
}

.tresorerieListStatus {
  color: darkgreen;
  position: relative;
  margin-left: 20px !important;
  padding-left: 30px !important;
  width: initial !important;
  border-left: 1px solid rgba(0, 100, 0, 0.411);
  h5 {
    position: relative;
    background-color: var(--section);
    width: 100%;
    padding: 8px;
    border-radius: 3px;
  }
  h5:after {
    content: "";
    height: 12px;
    width: 12px;
    position: absolute;
    top: 13px;
    left: -37px;
    background-color: green;
    border-radius: 50%;
    border: 1px solid darkgreen;
    opacity: 1;
  }
  h5:before {
    width: 45px;
    position: absolute;
    background-color: white;
    top: 40px;
    left: -55px;
    opacity: 1;
    font-size: 0.5em;
    font-weight: normal;
    text-align: center;
    padding: 2px;
  }
  h5.depotToDepose:before {
    content: "Argent remis";
  }
  h5.depotToValid:before {
    content: "Déposé à la banque";
  }
  h5.depotToDistrib:before {
    content: "Paiement validé";
  }
  h5.depotCloture:before {
    content: "Encaissé";
  }
  h5.achatToPaiement:before {
    content: "Payé";
  }
  h5.achatToDepose:before {
    content: "Argent remis";
  }
  h5.achatToValid:before {
    content: "Déposé à la banque";
  }
  h5.achatToDistrib:before {
    content: "Paiement validé";
  }
  h5.achatCloture:before {
    content: "Distribué";
  }
}

/*.photoArticle {
  max-height: 150px;
  max-width: 150px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}*/
